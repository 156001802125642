
import BillOfLandingAddForm from '@/wms/infrastructure/ui/billOfLanding/BillOfLandingAddForm.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import FooterModal from './FooterModals/FooterModal.vue';
import HeaderModal from './HeaderModals/HeaderModal.vue';
import html2pdf from 'html2pdf.js';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import { Inject } from '@/core/di/Inject';
import { TYPES } from '@/core/config/Types';
import { CreateBillOfLandingForPreRegister } from '@/wms/application/billOfLanding/create/CreateBillOfLandingForPreRegister';
import { FindMasterBLByPreRegister } from '@/wms/application/billOfLanding/search/FindMasterBLByPreRegister';
import { FindTransactionalActivityById } from '@/tracking/application/uses_cases/activitiesTransactional/search/FindTransactionalActivityById';
import { ActivityTransactional } from '@/tracking/domain/activitiesTransactional/ActivityTransactional';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';

//Modal C10: Add Bill of Landing
@Component({
  name: 'ModalC10',
  components: { HeaderModal, BillOfLandingAddForm, FooterModal }
})
export default class ModalC10 extends Vue {
  @Inject(TYPES.MASTER_BILL_OF_LANDING_CREATE_FOR_PRE_REGISTER)
  private readonly createMasterBL!: CreateBillOfLandingForPreRegister;
  @Inject(TYPES.MASTER_BILL_OF_LANDING_FIND_BY_PRE_REGISTER)
  private readonly findMasterBL!: FindMasterBLByPreRegister;
  @Inject(TYPES.ACTIVITIES_TRANSACTIONAL_FIND_BY_ID)
  private readonly findActivityById!: FindTransactionalActivityById;

  @Prop() isLoading!: boolean;
  @Prop() activityData!: ActivityTransactionalForTraceability;
  @Prop() eventData!: TransactionalEventForTraceability;
  @Prop({ type: Function }) saveProcess!: (activityData: ActivityTransactional) => Promise<void>;
  @Prop() typeOperation!: string;
  @Prop() typeTransport!: string;
  @Prop() master!: PreRegisterForTraceabilityPanel;
  @Prop({ type: Function }) searchActivities!: () => Promise<void>;

  formBL: BillOfLanding = new BillOfLanding();
  actions = {
    save: this.saveBL,
    clear: this.clearBl
  };
  modalActivityData: ActivityTransactional | null = null;

  localIsLoading = false;

  get clientsByBulks() {
    // return [...this.master.bulks.map(bulk => bulk.owner)];
    return [];
  }

  get modalTitle() {
    return `${this.eventData.description} - ${this.$t('switchs.' + this.activityData?.sw)} - ${
      this.activityData.keyModal
    }`;
  }

  // mounted() {
  //   if (this.typeTransport === 'Ocean' && this.activityData?.billOfLanding) {
  //     this.formBL = this.activityData?.billOfLanding;
  //   }
  //   if (this.typeTransport === 'Air' && this.activityData?.airWayBill) {
  //     this.formBL = this.activityData?.airWayBill;
  //   }
  // }

  async saveBL() {
    try {
      if (!this.modalActivityData) return;
      this.localIsLoading = true;
      await this.createMasterBL.internalExecute({
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type,
        billOfLanding: this.formBL
      });

      this.$bvModal.hide('C10');

      this.saveProcess(this.modalActivityData);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  clearBl() {
    this.formBL = new BillOfLanding();
  }

  exportToPDF(id: string) {
    const config = {
      margin: 0.1,
      pagebreak: { mode: 'avoid-all' },
      filename: id,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'in', format: 'legal', orientation: 'p' }
    };
    html2pdf(document.getElementById(id), config);
  }

  async findBLData() {
    try {
      this.localIsLoading = true;
      const res = await this.findMasterBL.internalExecute({
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type
      });

      if ('message' in res) {
        this.formBL = new BillOfLanding();
        return;
      }
      this.formBL = res;
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  async findActivityData() {
    try {
      if (!this.modalActivityData) return;

      this.localIsLoading = true;

      this.modalActivityData = await this.findActivityById.execute({
        activityId: this.activityData?.activityId,
        eventId: this.eventData.eventId,
        preRegisterNumber: this.master.number,
        preRegisterType: this.master.type
      });
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.localIsLoading = false;
    }
  }

  async findData() {
    await this.findActivityData();
    await this.findBLData();
  }
}
