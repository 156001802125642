export class HouseBillOfLading {
  totalVolume: number;
  totalItems: number;
  totalWeight: number;
  cargoType: string;
  productName: string;

  constructor() {
    this.totalVolume = 0;
    this.totalItems = 0;
    this.totalWeight = 0;
    this.cargoType = '';
    this.productName = '';
  }
}
